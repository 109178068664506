import '../sass/style';
import jquery from 'jquery';
import accordion from './accordion.js';
import megamenu from './megamenu.js';
import bootstrap from './bootstrap.js';


jQuery(document).ready(function($){

    $(".accordion_example").smk_Accordion({
            showIcon: true, //boolean
            animation: true, //boolean
            closeAble: true, //boolean
            slideSpeed: 200 //integer, miliseconds
        });
});